import CenterCard from "./CenterCard";
import BuildFullAddress from "../../utilities/BuildFullAddress";
import FormatCenterName from "../../utilities/FormatCenterName";
import useHttp from "../parts/useHttp";
import Paginator from "../parts/Paginator";
import CentersSeo from "./CentersSeo";
import NumberWithDot from "../../utilities/NumberWithDot";
import ReMap from "../parts/ReMap";
import CtaCooperate from "../parts/CtaCooperate";
import CentersServiceTitle from "./CentersService/CentersServiceTitle";
import CentersVipCard from "./CentersVipCard";

const CentersList = (props) => {

  let seoPage = "CENTERS";
  let baseURL = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers?`;
  let queryParameters = [];

  if (props.activeTab === "geo") {
    if (props.localityId) {
      queryParameters.push(`locality_id=${props.localityId}`);
      seoPage = "LOCALITY";
    } else if (props.provinceId) {
      queryParameters.push(`province_id=${props.provinceId}`);
      seoPage = "PROVINCE";
    } else if (props.regionId) {
      queryParameters.push(`region_id=${props.regionId}`);
      seoPage = "REGION";
    }
  }

  if (props.activeTab === "key" && props.keyText) {
    queryParameters.push("search=" + props.keyText);
    seoPage = "SEARCH";
  }

  if (props.radioValue === "private") {
    queryParameters.push("public=false");
  } else if (props.radioValue === "public") {
    queryParameters.push("public=true");
  }

  if (props.categorySlug) {
    queryParameters.push(`category_slug=${props.categorySlug}`);
  }

  if (props.categoryId) {
    queryParameters.push(`category_id=${props.categoryId}`);
  }

  if (props.lat) {
    queryParameters.push(`latitude=${props.lat}`);
  }

  if (props.lng) {
    queryParameters.push(`longitude=${props.lng}`);
  }

  if (props.page && props.page > 1) {
    queryParameters.push(`page=${props.page}`);
  }
  let url = baseURL + queryParameters.join('&');

  const {data, headers, loading, error} = useHttp(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return (
      <div className="loader-container loader-container-map"></div>
    );
  }
  if (error) console.log(error);

  if (!data || data?.data.length < 1) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }

  var centersLatLong = [];

  data?.data.map((center) => {
    centersLatLong.push({
      latitude: center.geo_data.latitude,
      longitude: center.geo_data.longitude,
      index: center.id
    });
  })

  var pageIndexMsg = props.page && props.page > 1 ? ` (pagina ${props.page})` : "";


  return(
    <>
      { (props.caller === "centers") &&
        <CentersSeo
        seoPage={seoPage}
        localityName={data?.data[0].geo_data.locality_name}
        provinceName={data?.data[0].geo_data.province_name}
        regionName={data?.data[0].geo_data.region_name}
        keyText={props.keyText}
      />}
      <ReMap markersLatLong={centersLatLong} latUser={props.lat} lngUser={props.lng} />
      {(props.nearToAddress) && 
        <CentersServiceTitle categoryName={props.categoryName} nearToAddress={props.nearToAddress} />
      }
      <div className="container pt-1 pb-5">
        <div className="row">
          <div className="col-md-12">
            <p>Sono stati trovati <b>{NumberWithDot(headers?.['total-count'])}</b> centri medici e ambulatori di cui {data?.data.length}  visualizzati<i>{pageIndexMsg}</i></p>
            <div className="slgo-centers-list">
              <CentersVipCard caller={props.caller} categoryId={props.categoryId} categorySlug={props.categorySlug} lat={props.lat} lng={props.lng} />
              {data?.data.map((center) => (
                <CenterCard
                  centerId={center['id']}
                  centerName={FormatCenterName(center['center_name'])}
                  fullAddress={
                    <BuildFullAddress
                      streetName={center['geo_data']['street_name']}
                      streetNumber={center['geo_data']['street_number']}
                      postalCode={center['geo_data']['postal_code']}
                      localityName={center['geo_data']['locality_name']}
                      provinceName={center['geo_data']['province_name']}
                      regionName={center['geo_data']['region_name']}
                    />}
                  public={center['public']}
                  logo={center['logo']}
                  description={center['description']}
                  ssnCompliant={center['ssn_compliant']}
                  centerSlug={center['slug']}
                  serviceSlug={center?.service?.slug}
                  categoryName={props.categoryName}
                  distance={center['geo_data']['distance']}
                  caller={props.caller}
                />)
              )}
            </div>
          </div>
          <div className="col-md-12">
            <Paginator
              totalCount={parseInt(headers?.['total-count'])}
              itemsPerPage={parseInt(headers?.['total-count'])}
              totalPages={parseInt(headers?.['total-pages'])}
              currentPage={parseInt(headers?.['current-page'])} 
              scrollElement='center-map'
              setPage={props.setPage}
            />
          </div>
        </div>
      </div>
      <CtaCooperate/>
    </>
  );
}

export default CentersList;